<template>
  <Layout :tituloPagina="`Network | DHs | Modelos | ${ modo == 'nuevo' ? 'Nuevo' : modelo.identificador+' | Edicion' }`">
    <div class="row">
      <div class="col-md-5">
        <div class="card">
          <div class="card-header justify-content-between d-flex align-items-center">
            <h4 class="card-title">
              {{ modo == 'nuevo' ? 'Nuevo' : 'Edición de' }}
              modelo de DHs
            </h4>
            <div>
              <div class="dropdown">
                <a
                  class="dropdown-toggle"
                  href="javascript: void(0);"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <eva-icon
                    name="more-horizontal-outline"
                    data-eva-width="20"
                    data-eva-height="20"
                    :class="{
                      'fill-dark': $store.state.layout.layoutMode == 'light',
                      'fill-white': $store.state.layout.layoutMode == 'dark'
                    }"
                  ></eva-icon>
                </a>
                <ul class="dropdown-menu dropdown-menu-end">
                  <li>
                    <a
                      href="#"
                      class="dropdown-item"
                      onclick="return false"
                      @click="modo == 'nuevo' ? guardar() : actualizar()"
                      v-if="!bandera_spinner"
                    >
                      <i class="mdi mdi-content-save text-success"></i>
                      {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                    </a>
                    <span class="dropdown-item disabled" v-if="bandera_spinner">
                      <i class="mdi mdi-concourse-ci mdi-spin"></i>
                      {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                    </span>
                  </li>
                  <li>
                    <a
                      class="dropdown-item" href="#"
                      onclick="return false;" @click="cerrar()"
                    >
                      <i class="mdi mdi-close text-dark"></i>
                      Cerrar
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <label class="col-sm-4">Identificador</label>
              <div class="col-sm-8">
                <input
                  ref="identificador"
                  name="identificador"
                  class="form-control"
                  placeholder="Identificador de modelo"
                  v-model="modelo.identificador"
                />
              </div>
            </div>
            <br>
            <div class="row">
              <label class="col-sm-4">Marca</label>
              <div class="col-sm-8">
                <select
                  ref="marca"
                  name="marca"
                  class="form-select"
                  v-model="modelo.id_marca"
                >
                  <option :value="null">Seleccionar</option>
                  <option v-for="marca in marcas" :key="marca.id" :value="marca.id">
                    {{ marca.nombre }}
                  </option>
                </select>
              </div>
            </div>
            <br>
            <div class="row">
              <label class="col-md-4">Foto</label>
              <div class="col-md-8">
                <div id="marcoFoto">
                  <div
                    id="divBtnFoto"
                    @click="agregarArchivo()"
                    class="text-center"
                    v-if="srcFoto == ''"
                  >
                    Da click aquí para cargar una imágen
                  </div>
                  <div v-if="srcFoto != ''">
                    <button
                      type="button"
                      @click="eliminarArchivo()"
                      class="btnEliminarFoto btn btn-danger btn-sm btn-rounded"
                    >
                      <i class="mdi mdi-close-thick"></i>
                    </button>
                    <img id="imagenModelo" :src="srcFoto" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="text-right">
          <div class="btn-group">
            <button class="btn btn-secondary" @click="cerrar()">
              <i class="mdi mdi-chevron-left"></i>
              Atrás
            </button>
            <button
              class="btn btn-success"
              @click="modo == 'nuevo' ? guardar() : actualizar()"
              :disabled="bandera_spinner"
            >
              <i
                class="mdi"
                :class="!bandera_spinner ? 'mdi-content-save' : 'mdi-concourse-ci mdi-spin'"
              ></i>
              {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <br>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main"
import API from '@/API.js'
import ModeloDhsSrv from '@/services/ModeloDhsSrv.js'
import MarcaDhsSrv from '@/services/MarcaDhsSrv.js'
export default {
  name: 'EdicionModeloDhs',
  components: { Layout },
  data() {
    return {
      marcas: [],
      modelo: {
        id_marca: null,
        identificador: '',
        _eliminarFotoAntigua: false
      },
      modeloInicial: {},
      srcFoto: '',
      modo: 'nuevo',
      bandera_spinner: false
    }
  },

  created: function() {
    var self = this

    if (self.$route.path.indexOf('edicion') >= 0) self.modo = 'edicion'

    // Cargas iniciales
    if (self.modo == 'nuevo' && self.$route.query.id_marca) self.modelo.id_marca = parseInt(self.$route.query.id_marca)
    
    self.cargarMarcas()

    // Registro de una copia del modelo inicial
    self.modeloInicial = Object.assign({}, self.modelo)

    if (this.modo == 'edicion') self.cargarModelo()
  },

  methods: {
    actualizar: function() {
      var self = this
      var modelo = Object.assign({}, self.modelo)

      self.bandera_spinner = true

      if(modelo.identificador == '') {
        iu.msg.warning('Se necesita el identificador del modelo para el DH')
        self.$refs.identificador.select()
        self.bandera_spinner = false
        return
      }

      if(modelo.id_marca == null) {
        iu.msg.warning('Se necesita una marca selecicionada del modelo para el DH')
        self.$refs.marca.focus()
        self.bandera_spinner = false
        return
      }

      ModeloDhsSrv.actualizar(modelo).then(response => {
        iu.msg.success('Se actualizó correctamente')
        self.cargarModelo()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo actualizar'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        self.bandera_spinner = false
      })
    },

    agregarArchivo: function() {
      var self = this

      var marcoFoto = document.getElementById("marcoFoto")
      var input = document.createElement("input")
      input.setAttribute('type', 'file')
      input.setAttribute('name', 'foto')
      input.setAttribute('style', 'display: none')
      marcoFoto.append(input)

      input.click()
      input.addEventListener('change', function(e) {
        var input = e.target

        var reader = new FileReader()
        reader.onload = function(e1) {
          self.srcFoto = e1.target.result
        }

        if (input.files.length > 0) {
          reader.readAsDataURL(input.files[0])
        }
      })
    },

    cargarMarcas: function() {
      var self = this
      
      MarcaDhsSrv.marcasJSON().then(response => {
        self.marcas = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar las marcas'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarModelo: function() {
      var self = this
      let idModelo = self.$route.params.id

      ModeloDhsSrv.modeloJSON(idModelo).then(response => {
        let modelo = response.data
        self.modelo = modelo

        if (
          modelo.id_foto != null &&
          modelo.id_foto != '' &&
          modelo.id_foto != 0
        )
          self.srcFoto =
            API +
            '/dhs/modelos/' +
            modelo.id +
            '/imagen?tipo_foto=256x256&_tk=' +
            localStorage.getItem('argusblack.token') +
            '&_tiempo=' +
            new Date().getTime()
        else self.srcFoto = ''
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar el modelo'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cerrar: function() {
      this.$router.go(-1)
    },
    eliminarArchivo: function() {
      var self = this

      self.modelo._eliminarFotoAntigua = true
      self.srcFoto = ''

      var inputs = document.getElementsByName("foto")
      if(inputs.length > 0) inputs[0].remove()
    },

    guardar: function() {
      var self = this
      var modelo = Object.assign({}, self.modelo)

      self.bandera_spinner = true

      if(modelo.identificador == '') {
        iu.msg.warning('Se necesita el identificador del modelo para el DH')
        self.$refs.identificador.select()
        self.bandera_spinner = false
        return
      }

      if(modelo.id_marca == null) {
        iu.msg.warning('Se necesita una marca selecicionada del modelo para el DH')
        self.$refs.marca.focus()
        self.bandera_spinner = false
        return
      }

      ModeloDhsSrv.guardar(modelo).then(response => {
        iu.msg.success('Se guardó correctamente')
        self.limpieza()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo guardar el modelo'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        self.bandera_spinner = false
      })
    },

    limpieza: function() {
      var self = this

      self.modelo = Object.assign(self.modelo, self.modeloInicial)
      self.srcFoto = ''
    }
  },
}
</script>

<style scoped>
input[type='file'] {
  display: none;
}

#imagenModelo {
  width: 100%;
}

#marcoFoto {
  border: thin solid #ccc;
  border-radius: 0.75rem;
  background-color: #fafafa;
  width: 100%;
  min-height: 100px;
  cursor: pointer;
  overflow: hidden;
  position: relative;
}

#divBtnFoto {
  width: 100%;
  height: 100px;
  line-height: 100px;
}

.btnEliminarFoto {
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 10;
}
</style>